import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './BlogDetail.css';
import { BASE_URL } from '../config/Config';

function BlogDetail() {
  const { id } = useParams();
  const [article, setArticle] = useState(null);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await fetch(`${BASE_URL}articles/feature-news/${id}`);
        const data = await response.json();
        setArticle(data);
      } catch (error) {
        console.error('Error fetching article:', error);
      }
    };
    
    fetchArticle();
  }, [id]);

  const formattedDate = (date) => new Date(date).toLocaleDateString('en-US', {
    weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
  });

  return (
    <div className="blog-detail">
      {article ? (
        <>
          <header className="article-header">
            <h1 className="article-title">{article.title}</h1>
            <div className="article-meta">
              <p className="author">
                <i className="fa fa-user"></i> {article.author || 'Unknown Author'}
              </p>
              <p className="date-posted">
                <i className="fa fa-calendar"></i> {formattedDate(article.created_at)}
              </p>
            </div>
          </header>

          <div className="article-image-container">
            <img src={article.image} alt={article.title} className="article-image" />
          </div>

          <section className="article-content">
            <h2 className="subtitle">{article.subtitle}</h2>
            <p className="description">{article.description}</p>
          </section>

          {/* Display additional fields independently */}
          {article.subtitle2 && (
            <section className="article-section">
              <h2 className="section-title">{article.title2}</h2>
              <h3 className="section-subtitle">{article.subtitle2}</h3>
              {article.image_section2 && (
                <div className="section-image-container">
                  <img src={article.image_section2} alt={article.title2} className="section-image" />
                </div>
              )}
              <p className="section-description">{article.description2}</p>
            </section>
          )}

          {article.subtitle3 && (
            <section className="article-section">
              <h2 className="section-title">{article.title3}</h2>
              <h3 className="section-subtitle">{article.subtitle3}</h3>
              {article.image_section3 && (
                <div className="section-image-container">
                  <img src={article.image_section3} alt={article.title3} className="section-image" />
                </div>
              )}
              <p className="section-description">{article.description3}</p>
            </section>
          )}

          {article.subtitle4 && (
            <section className="article-section">
              <h2 className="section-title">{article.title4}</h2>
              <h3 className="section-subtitle">{article.subtitle4}</h3>
              {article.image_section4 && (
                <div className="section-image-container">
                  <img src={article.image_section4} alt={article.title4} className="section-image" />
                </div>
              )}
              <p className="section-description">{article.description4}</p>
            </section>
          )}

          {article.subtitle5 && (
            <section className="article-section">
              <h2 className="section-title">{article.title5}</h2>
              <h3 className="section-subtitle">{article.subtitle5}</h3>
              {article.image_section5 && (
                <div className="section-image-container">
                  <img src={article.image_section5} alt={article.title5} className="section-image" />
                </div>
              )}
              <p className="section-description">{article.description5}</p>
            </section>
          )}

          {article.subtitle6 && (
            <section className="article-section">
              <h2 className="section-title">{article.title6}</h2>
              <h3 className="section-subtitle">{article.subtitle6}</h3>
              {article.image_section6 && (
                <div className="section-image-container">
                  <img src={article.image_section6} alt={article.title6} className="section-image" />
                </div>
              )}
              <p className="section-description">{article.description6}</p>
            </section>
          )}

          {article.subtitle7 && (
            <section className="article-section">
              <h2 className="section-title">{article.title7}</h2>
              <h3 className="section-subtitle">{article.subtitle7}</h3>
              {article.image_section7 && (
                <div className="section-image-container">
                  <img src={article.image_section7} alt={article.title7} className="section-image" />
                </div>
              )}
              <p className="section-description">{article.description7}</p>
            </section>
          )}

          {/* Display additional images if they exist */}
          {article.additional_images && (
            <div className="additional-images">
              <h3>Additional Images</h3>
              <img src={article.additional_images} alt="Additional" className="additional-image" />
            </div>
          )}

          {/* Display video if it exists */}
          {article.video && (
            <div className="article-video">
              <h3>Related Video</h3>
              <iframe 
                width="100%" 
                height="400" 
                src={article.video} 
                title="Related video" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen 
              ></iframe>
            </div>
          )}
        </>
      ) : (
        <p>Loading article...</p>
      )}
    </div>
  );
}

export default BlogDetail;
